.question-container label {
    font-weight: bold;
}

.question-container textarea {
    min-height: 200px;
}

.question-container .question {
    background-color: #f7f7f7;
    color: #0f9ed5;
    height: auto !important;
    min-height: calc(1.5em + 0.75rem + 2px);
}

.question-container .p-inputgroup-addon,
.question-container .p-inputtext {
    padding: 5px;
    min-width: 2rem;
}

.quiz-title {
    background-color: #0f9ed5;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    padding: 5px 15px;
    float: right;
}

.quiz-left-title {
    color: #0f9ed5;
    font-weight: bold;
    font-size: 1.5rem;
}

.quiz-card {
    background-color: #f2f2f2;
}

.quiz-card.active {
    background-color: #a9e4f9;
}

.quiz-card.answered {
    background-color: #a0eea6 !important;
}
.quiz-card.answered.confident {
    background-color: #62e76d !important; /* Light green for confident answers */
}

.p-confirm-popup-footer {
    display: flex !important;
    justify-content: end !important;
    gap: 10px !important;
    flex-wrap: wrap !important; /* ✅ Prevents overlapping on small screens */
}
