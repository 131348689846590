body {
    background: #fff;
    margin: 0;
    padding: 0;
    border: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.dashboard {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.9) 0 100%), url(/public/cyberbay.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 15%;
    height: calc(100vh - 110px);
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #f5f5f5;
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #ccc;
    background-color: #ccc;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.header {
    width: 100%;
    z-index: 10;
}

.error:not(li):not(input) {
    color: red;
    font-size: 85%;
    margin-top: 0.25rem;
}

.cursor-pointer {
    cursor: pointer;
}

.header .menu ul {
    float: right;
    list-style: none;
    padding: 10px;
    margin: 0 0 0 auto;
}

.header .menu ul li,
.header .menu ul li a {
    opacity: 0.8;
    cursor: pointer;
    transition: 200ms;
    text-decoration: none;
    white-space: nowrap;
    font-weight: 700;
}

.header .menu ul li:hover,
.header .menu ul li a:hover {
    opacity: 1;
}

.header .menu ul li a,
.header .menu ul li a a {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}

.header .menu ul li {
    padding-right: 16px;
}

.header .menu ul li::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #ffa500;
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
}

.header .menu ul .link::before {
    padding-right: 0;
    display: none;
}

.header .menu > ul {
    display: flex;
    align-items: center;
}

.header .menu > ul li {
    position: relative;
    margin: 0 8px;
}

.header .menu > ul li ul {
    visibility: hidden;
    opacity: 0;
    padding: 0;
    margin-top: 10px;
    min-width: 160px;
    background-color: #ddd;
    position: absolute;
    top: calc(var(--menu-height) + 5px);
    left: 50%;
    transform: translateX(-50%);
    transition: 200ms;
    transition-delay: 200ms;
    z-index: 2;
}

.header .menu > ul li:last-child ul {
    right: -89px;
}

.header .menu > ul li ul li {
    margin: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 2px solid #ccc;
}

.header .menu > ul li ul li::before {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #ffa500;
}

.header .menu > ul li ul li ul {
    top: -2%;
    left: 100%;
    transform: translate(0);
}

.header .menu > ul li ul li:hover {
    background-color: #ddd;
}

.header .menu > ul li:hover > ul {
    opacity: 1;
    visibility: visible;
    transition-delay: 5ms;
}

.commando-container {
    width: 250px;
    background-color: #f9f9f9;
    border-radius: 10px;
}

.commando-container .title {
    background-color: #0f9ed5;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    padding: 5px;
}

.commando-container .body {
    padding: 15px 10px;
}

.commando-container .body label {
    color: #0f9ed5;
    font-size: 16px;
}

.commando-container .body span {
    font-size: 16px;
}

.quiz-container {
    width: 300px;
}

.quiz-container .title {
    background-color: #f9f9f9;
    background-color: #0f9ed5;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    padding: 5px;
}

.quiz-container .body {
    background-color: #f9f9f9;
    padding: 15px 10px;
    border-radius: 10px;
}

.quiz-container .body label {
    color: #0f9ed5;
    font-size: 14px;
}

.quiz-container .body span {
    font-size: 14px;
}

.quiz-container .completed span {
    background-color: #0f9ed5;
    color: #ffffff;
    font-weight: bold;
    border-radius: 20px;
    padding: 10px 30px;
}

.quiz-container .score-container span {
    background-color: #f2f2f2;
    color: #0f9ed5;
    font-weight: bold;
    border-radius: 20px;
    padding: 10px 30px;
}

.quiz-container .score-container .score {
    color: green;
}

.quiz-container.border {
    border-radius: 10px;
    padding-bottom: 10px;
    border-color: #eee !important;
}

.btn-impersonate {
    color: #46b1e1;
}

@media print {
    body {
        display: none;
    }
}
