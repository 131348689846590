:focus-visible {
    outline: none;
}

a {
    color: #160f0f;
}

a:hover {
    color: #160f0f;
}

.profile-container {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    /* height: calc(100vh); */
    background-image: url(/public/assets/img/profilebg.png);
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
}

.profile-container {
    background: none;
}

.profile-container .profile-image {
    display: none;
}

.profile-container .row {
    margin-left: 0px;
    margin-right: 0px;
}

.profile-container .profile-image {
    height: calc(100vh);
}

.profile-container .profile-image img {
    margin-top: 5rem;
    height: 25rem;
    top: 7rem;
    right: 5rem;
    position: absolute;
}

/* .profile-container .profile-form {
  padding: 15rem 15rem 0rem 15rem;
  margin: auto;
} */

.profile-container .profile-form h1 {
    font-size: 24px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    font-weight: bold;
    color: #0b76a0;
    text-align: center;
}

/* .profile-container .profile-form form {
  margin: auto;
  padding: 1rem 2rem;
} */

.profile-container .profile-form form .captcha-holder > div > div > div {
    margin: auto;
}

.profile-container .profile-form form .input-icons {
    margin: auto;
    position: relative;
}

.profile-container .profile-form form .input-icons .input-container-username:before {
    content: "";
    position: absolute;
    background-color: #fff;
    top: 3.5px;
    left: 19px;
    width: 37px;
    height: 37px;
    border-radius: 100%; /* rounded corners */
    box-shadow: 0 0 10px rgb(37, 36, 36); /* shadow effect */
    background-image: url(/public/assets/img/user.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    z-index: 1; /* place behind the input field */
}

.profile-container .profile-form form .input-icons .input-container-password:before {
    content: "";
    position: absolute;
    background-color: #fff;
    top: 3.5px;
    left: 19px;
    width: 37px;
    height: 37px;
    border-radius: 100%; /* rounded corners */
    box-shadow: 0 0 10px rgb(37, 36, 36); /* shadow effect */
    background-image: url(/public/assets/img/lock.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    z-index: 1; /* place behind the input field */
}

.profile-container .profile-form form .input-icons input {
    background-color: #f5f5f5;
    border: none;
    padding: 10px 10px 10px 60px;
    display: block;
    border-radius: 2rem 0.5rem 0.5rem 2rem;
    width: 100%;
}

.profile-container .profile-form form .input-icons .otp-textbox {
    text-align: center;
    padding: 10px 10px 10px 10px;
}

.profile-container form .profile-button {
    display: inline-block;
    background-color: #00b0f0;
    border: none;
    padding: 0.35rem 1.5rem;
    border-radius: 10px;
    color: #fff;
    margin: auto;
    align-items: center;
}

.profile-container form .profile-cancel-button {
    display: inline-block;
    background-color: grey;
    border: none;
    padding: 0.35rem 1.5rem;
    border-radius: 10px;
    color: #fff;
    margin: auto;
    align-items: center;
}

input:focus-visible {
    outline-offset: none;
}

/* The container */
.profile-container .profile-form form .checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: bold;
}

/* Hide the browser's default checkbox */
.profile-container .profile-form form .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.profile-container .profile-form form .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #f5f5f5;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.profile-container .profile-form form .checkbox-container:hover input ~ .checkmark {
    background-color: #f5f5f5;
}

/* When the checkbox is checked, add a blue background color */
.profile-container .profile-form form .checkbox-container input:checked ~ .checkmark {
    background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.profile-container .profile-form form .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.profile-container .profile-form form .checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.profile-container .profile-form form .checkbox-container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.profile-container .questions-form {
    height: calc(100vh);
    padding: 12rem 0rem 0rem 0rem;
    margin: auto;
}

.profile-container .questions-form form table {
    width: 100%;
    max-width: 900px;
}
.profile-container .questions-form form table th,
.profile-container .questions-form form table td {
    width: 45%;
    max-width: 45%;
    min-width: 45%;
}
.profile-container .questions-form form table th:first-child,
.profile-container .questions-form form table td:first-child {
    width: 10% !important;
    max-width: 10% !important;
    min-width: 10% !important;
}

.no-background {
    background-image: none !important;
}

.profile {
    padding-top: 10rem;
    font-size: 18px;
}

.profile h1 {
    font-size: 36px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    font-weight: bold;
    color: #104862;
    text-align: center;
}

.profile a,
.profile button.anchor {
    color: #007bff;
    border: none;
    background: none;
}
.profile button.anchor:hover {
    text-decoration: underline;
}
.profile button.anchor:focus {
    outline: none;
}

.profile-container .profile-icon > div {
    border-radius: 100%;
    width: 70px;
    height: 70px;
    margin: auto;
    text-align: center;
    background: linear-gradient(
        180deg,
        rgba(160, 105, 246, 1) 0%,
        rgba(146, 101, 247, 1) 16%,
        rgba(109, 90, 250, 1) 47%,
        rgba(50, 72, 254, 1) 89%,
        rgba(34, 67, 255, 1) 100%
    );
}

.profile-container .profile-icon > div > img.display-name {
    height: 45px;
    margin-top: 10px;
    margin-left: -3px;
}

.profile-container .profile-icon > div > img.email {
    height: 78px;
    margin-top: 10px;
    margin-left: 1px;
}

.profile-container .profile-icon > div > img.mobile {
    height: 70px;
}

.profile-container .profile-icon > div > img.mfa {
    height: 70px;
    margin-top: -5px;
}

.profile-container .profile-icon > div > img.security-questions {
    height: 70px;
}

.profile-container .profile-name {
    margin: auto;
    color: #156082;
}

.profile-container .profile-value {
    margin: auto;
}

.profile-container .otp-input {
    max-width: 300px;
}

.profile-container .p-inputotp {
    margin: auto;
    max-width: 275px;
}

@media (min-width: 200px) {
    .profile-container .profile-form {
        padding: 10rem 1rem;
    }
}

@media (min-width: 576px) {
    .profile-container .profile-form {
        padding: 15rem 5rem;
    }
}

@media (min-width: 768px) {
    .profile-container .profile-form {
        padding: 15rem 10rem;
    }
}

@media (min-width: 992px) {
    .profile-container .profile-form {
        padding: 15rem 20rem;
    }
}

@media (min-width: 1200px) {
    .profile-container {
        background-image: url(/public/assets/img/profilebg.png);
        background-repeat: no-repeat;
        background-position: left;
        background-position-y: bottom;
        background-size: 400px;
        height: calc(100vh);
    }

    .profile-container .profile-form {
        padding: 15rem 1rem;
    }

    .profile-container .profile-form form {
        padding: 0px;
    }

    .profile-container .profile-form .input-icons input {
        display: block;
        margin: 0 auto;
    }

    .profile-container .profile-form .checkbox-container {
        padding: auto;
    }
    .change-password {
        max-width: 800px;
    }
}
