body {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
}

.greeting-text {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    font-size: 24px;
    color: #46b1e1;
    margin-left: 20px;
}

.right-top-container {
    gap: 20px;
}

.img-settings {
    height: 25px;
}

.position-fixed {
    position: fixed;
}

.wrapper {
    padding-left: 50px;
    height: calc(100vh - 101px);
}

.sidebar-wrapper {
    margin-left: -50px;
    left: 50px;
    width: 50px;
    background: linear-gradient(90deg, #0f9ed5 0%, #00b0f0 100%);
    position: fixed;
    height: calc(100% - 115px);
    z-index: 1;
    top: 115px;
    overflow: auto;
    background-color: #f7f7f7;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    display: grid;
    /* transition   : all .4s ease 0s; */
}

.page-content-wrapper {
    margin-left: 0;
    width: 100%;
    height: auto;
    margin-top: 35px;
    padding: 0px 15px;
}

.menu-icon-holder {
    text-align: center;
}

.sidebar-wrapper .menu-icon-holder:first-child {
    padding-top: 20px;
}

.menu-icon-holder img {
    height: 25px;
    margin: auto;
}

.dashboard-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 15px 0px;
    padding: 25px 30px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 2px;
}

.dashboard-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.dashboard-card img {
    height: 75px;
}

.dashboard-card span {
    padding-top: 5px;
    font-weight: bold;
    display: block;
}

.dashboard-card small {
    display: block;
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.course-card {
    margin: 15px 0px;
    padding: 25px 30px;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    justify-content: center;
}

.course-card img {
    height: 55px;
}

.course-card span {
    padding: 7px;
    margin-top: 10px;
    font-weight: bold;
    display: block;
    color: #000000 !important;
    background-color: #d1d1d187;
    border-radius: 5px;
}
