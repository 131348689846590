/* .course-incidents-container>div {
    padding: 0px 30px;
    height: calc(100vh - 200px);
} */

.course-incidents-container .action-container {
    position: relative;
    margin: auto;
    top: 50%;
    text-align: center;
}

.course-incidents-container .action-container button {
    border: 1px solid #eee;
    margin: 5px;
    width: 30px;
}

.course-incidents-container .repo-incidents,
.course-incidents-container .course-incidents {
    border: 1px solid #eee;
    height: calc(100vh - 210px);
    border-radius: 30px 30px 0px 0px;
}

.course-incidents-container .repo-incidents .title,
.course-incidents-container .course-incidents .title {
    border-bottom: 1px solid #eee;
    text-align: center;
    padding: 15px;
    color: #0f9ed5;
    font-family: inherit;
    font-size: 1.2rem;
}

.course-incidents-container .repo-incidents .search-group,
.course-incidents-container .course-incidents .search-group {
    border-bottom: 1px solid #eee;
}

.course-incidents-container .repo-incidents .repo-incidents-container,
.course-incidents-container .course-incidents .course-incidents-container {
    max-height: calc(100vh - 290px);
    overflow-x: hidden;
    overflow-y: auto;
}

.course-incidents-container .repo-incidents .incident-item,
.course-incidents-container .course-incidents .incident-item {
    background-color: #f2f2f2;
    display: block;
    padding: 7px;
    text-indent: 15px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.course-incidents-container .repo-incidents .incident-item:first-child,
.course-incidents-container .course-incidents .incident-item:first-child {
    border-top: 1px solid #ddd;
}

.course-incidents-container .repo-incidents .incident-item.selected,
.course-incidents-container .course-incidents .incident-item.selected {
    background-color: #0f9ed5;
    color: #ffffff;
}

.vm-title {
    background-color: #0f9ed5;
    color: #ffffff;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    margin: 0px 10px;
}

.vm-value {
    background-color: #f2f2f2;
    color: #000;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    margin: 0px 10px;
    height: 34px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.m-height {
    height: 90px;
}

.vm-value i {
    cursor: pointer;
    color: #aaa;
}

.vm-value i:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    color: black;
}

.vm-value input {
    background-color: #f2f2f2;
    color: #000;
    text-align: center;
    border: none;
    padding: 0px;
    height: 26px;
}

.vm-add-button {
    background-color: #0f9ed5;
    border: 1px #0f9ed5 solid;
    color: #fff;
    border-radius: 5px;
    padding: 3px 10px;
    margin: auto;
}

.vm-remove-button {
    background-color: red;
    border: 1px red solid;
    color: #fff;
    border-radius: 5px;
    padding: 3px 10px;
    margin: auto;
}

.vm-cancel-button {
    background-color: #f2f2f2;
    border: 1px #f2f2f2 solid;
    color: #000;
    border-radius: 5px;
    padding: 3px 10px;
    margin: auto;
}

.vm-add-button:hover,
.vm-remove-button:hover,
.vm-cancel-button:hover {
    background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
}

.vm-value .p-autocomplete,
.vm-value .p-autocomplete .p-autocomplete-input {
    width: 100%;
}

.incident-item .p-selectbutton .p-button {
    min-width: 25px !important;
    padding: 0px !important;
    border-radius: 0px !important;
    text-indent: 0px !important;
    outline: none !important;
}

.incident-item .p-selectbutton .p-button:first-child {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.incident-item .p-selectbutton .p-button:last-child {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.incident-item .p-selectbutton .p-button .p-button-label {
    text-align: center !important;
    outline: none !important;
}
