body {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
}

.custom-file-label {
    border: none;
    background-color: #f9f9f9;
}

.tox-statusbar__branding {
    display: none !important;
}

/* 
h1 {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 10px 0px;
  border-bottom: solid #ccc 1px;
} */

h1.title {
    color: #0f9ed5;
    font-size: 28px;
}

.label {
    color: #0f9ed5;
    font-size: 20px;
}

.value input,
.value select,
.value textarea,
.search-group .form-control {
    border: none;
    background-color: #f9f9f9;
}

.value a {
    color: #0f9ed5;
}

.value a:hover {
    color: #0f9ed5;
    text-decoration: underline;
}

.value .form-control:disabled,
.form-control[readonly] {
    background-color: #f9f9f9;
    opacity: 1;
}

.value textarea {
    resize: none;
    height: 100px;
}

.action button {
    width: 40px;
    border: none;
    background-color: #f9f9f9;
}

.steps {
    padding: 5px 0px;
    margin: 1px;
}

.steps:hover {
    border: solid #ccc 1px;
    border-radius: 5px;
}

.submit-button {
    display: inline-block;
    background-color: #00b0f0;
    border: none;
    padding: 0.35rem 1.5rem;
    border-radius: 10px;
    color: #fff;
    margin: auto;
    align-items: center;
}

.course-title .submit-button {
    font-size: 16px;
    float: right;
}

.remove-button {
    display: inline-block;
    background-color: #f00000;
    border: none;
    padding: 0.35rem 1.5rem;
    border-radius: 10px;
    color: #fff;
    margin: auto;
    align-items: center;
}

.cancel-button {
    display: inline-block;
    background-color: grey;
    border: none;
    padding: 0.35rem 1.5rem;
    border-radius: 10px;
    color: #fff;
    margin: auto;
    align-items: center;
}

.rdw-editor-wrapper {
    border: 1px solid #f1f1f1;
}

.text-decoration-line-through {
    text-decoration: line-through !important;
}

.link-button {
    border: none;
    background: none;
    outline: none;
    color: #0f9ed5;
}

.link-button:hover {
    border: none;
    background: none;
    outline: none;
    color: #0f9ed5;
}

.card {
    background-color: #f9f9f9;
    border-radius: 10px;
    border: none;
    margin: 15px 20px 15px 10px;
    padding: 15px;
    min-height: 150px;
    max-height: 150px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card.type {
    min-height: 100px;
    max-height: 100px;
}

.card>div {
    margin: auto 0px;
}

.card:hover {
    background-color: #f1f1f1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.card .name {
    color: #0f9ed5;
}

.card .title {
    width: 100%;
    /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.card.active {
    background: linear-gradient(90deg, #0b76a0 0%, #0f9ed5 100%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    color: #ffffff;
}

.card.active.completed {
    background: linear-gradient(90deg, #215f9a 0%, #156082 100%);
}

.card.active .name {
    color: #ffffff;
}

.incident-v-scroll {
    height: calc(100vh - 170px);
    overflow-y: auto;
    overflow-x: hidden;
}

.incident-list .card.active:before {
    content: "";
    position: absolute;
    background: linear-gradient(90deg, #0b76a0 0%, #0f9ed5 100%);
    margin-top: -8px;
    top: 50%;
    right: -18px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    /* rounded corners */
    z-index: 2;
    /* place behind the input field */
}

.incident-list .card.active>div:before {
    content: "";
    position: absolute;
    background-color: #0f9ed5;
    margin-top: -3px;
    top: 50%;
    right: -7px;
    width: 7px;
    height: 6px;
    z-index: 1;
    /* place behind the input field */
}

.h-incident-list .card.active:before {
    content: "";
    position: absolute;
    background: linear-gradient(90deg, #0b76a0 0%, #0f9ed5 100%);
    margin-left: -8px;
    left: 50%;
    bottom: -18px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    /* rounded corners */
    z-index: 2;
    /* place behind the input field */
}

.h-incident-list .card.active>div:before {
    content: "";
    position: absolute;
    background-color: #0f9ed5;
    margin-left: -3px;
    left: 50%;
    bottom: -7px;
    width: 6px;
    height: 7px;
    z-index: 1;
    /* place behind the input field */
}

.incident-view .incident-view-container {
    background-color: #f9f9f9;
    border-radius: 10px;
    height: calc(100vh - 170px);
    overflow: hidden;
    border: none;
    padding: 10px 0px 15px 10px;
}

.incident-view .incident-view-container>div {
    height: calc(100vh - 190px);
    overflow-x: hidden;
    overflow-y: auto;
}

.objective-view-container {
    height: calc(100vh - 170px);
    overflow-x: hidden;
    overflow-y: auto;
}

.objective-view-container .objective:not(:first-child) {
    margin-top: 20px !important;
}

.incident-view .incident-view-container span.title,
.objective span.title {
    color: #0f9ed5;
    font-weight: bold;
    font-size: 1.15rem;
}

.course-title {
    color: #0f9ed5;
    font-weight: bold;
    font-size: 1.5rem;
}

.course-title-value {
    color: #000000;
}

.h-incident-view .incident-view-container {
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    padding: 10px 0px 15px 10px;
}

.h-incident-view .incident-view-container>div {
    overflow: auto;
}

.h-incident-view .incident-view-container span,
.objective span {
    color: #0f9ed5;
    font-weight: bold;
    font-size: 1.25rem;
}

.h-carousel-container .p-carousel-item {
    max-width: 200px;
}

.h-carousel {
    width: calc(100%);
}

.h-incident-list {
    height: 170px;
    overflow: hidden;
}

.h-incident-list::-webkit-scrollbar {
    display: none;
}

.objective {
    border: solid #ddd 1px;
    border-radius: 15px;
}

.floating-button {
    position: fixed;
    width: 45px;
    height: 45px;
    bottom: 30px;
    right: 30px;
    background-color: #0f9ed5;
    border: none;
    color: #fff;
    border-radius: 100%;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
}

.floating-button.remove {
    background-color: #f00000;
}

.floating-button::selection {
    outline: none;
}

.p-chips,
.p-multiselect {
    width: 100%;
}

.p-chips-multiple-container,
.p-multiselect,
.p-dropdown {
    border: none;
    background-color: #f9f9f9;
    width: 100%;
}

.p-chips .p-chips-multiple-container {
    padding: 0.125rem 0.75rem;
}

.catalogue .p-inputtext {
    border: none;
}

.document-files .file,
.attachment-container .attachment {
    border-bottom: 1px solid #eee;
    background-color: #f9f9f9;
    padding: 5px 10px;
}

.attachment-container .attachment {
    overflow-y: visible;
}

.document-files>.attachment-container>.file:first-child,
.attachment-container .attachment:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.document-files>.attachment-container>.file:last-child,
.attachment-container .attachment:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: none;
}

.attachment-container .attachment:last-child {
    border-bottom: 1px solid #eee;
}

.view .view-container,
.view .doc-container {
    background-color: #f9f9f9;
    border-radius: 10px;
    height: calc(100vh - 120px);
    overflow: hidden;
    border: none;
    padding: 10px 0px 15px 10px;
}

.view .view-container>div,
.view .doc-container>div {
    overflow: auto;
}

.view .view-container span {
    color: #0f9ed5;
    font-weight: bold;
}

.course-doc-view .doc-container {
    overflow: auto;
}

.course-card div {
    background-color: #0f9ed5;
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    width: 75%;
    text-align: center;
    margin: auto;
}

.view .ticket-container {
    background-color: #f9f9f9;
    border-radius: 10px;
    height: calc(100vh - 240px);
    overflow: hidden;
    border: none;
    padding: 10px 0px 15px 10px;
}

.view .ticket-container>div {
    overflow: auto;
}

.view .ticket-container span {
    color: #0f9ed5;
    font-weight: bold;
}

.h-view .view-container,
.h-view .doc-container {
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    padding: 10px 0px 15px 10px;
}

.h-view .view-container>div,
.h-view .doc-container>div {
    overflow: auto;
}

.h-view .view-container span {
    color: #0f9ed5;
    font-weight: bold;
}

.attachment-container {
    border: none;
    max-height: calc(100vh - 250px);
    overflow-x: hidden;
    overflow-y: auto;
}

.view-container .attachment-container,
.doc-container .attachment-container,
.ticket-container .attachment-container {
    border: 1px solid #eee;
}

.solution-step .btn-icon,
.view-container .attachment .btn-icon,
.doc-container .attachment .btn-icon,
.ticket-container .attachment .btn-icon,
.question-container .btn-icon {
    background: none;
    border: none;
    cursor: pointer;
}

.view-container .attachment .btn-icon:hover,
.doc-container .attachment .btn-icon:hover,
.ticket-container .attachment .btn-icon:hover,
.question-container .btn-icon:hover {
    text-decoration: underline;
}

iframe {
    width: 100%;
    height: calc(100vh - 120px);
}

.btn-incident-back {
    display: flex;
    justify-content: center;
    border: none;
    background: none;
    float: right;
    font-size: 1rem;
    margin: 10px 0px 0px 0px;
}

.btn-incident-back span {
    font-size: 0.9rem;
    font-weight: bold;
}

.btn-incident-back i {
    color: #0f9ed5;
    font-size: 1.5rem;
    margin-right: 5px;
}

.btn-incident-all {
    color: #000000;
    background-color: #e1f7ff;
    display: flex;
    justify-content: center;
    border: none;
    float: right;
    border-radius: 10px;
    font-size: 1rem;
    margin: 10px 0px 0px 0px;
    padding: 5px 10px;
}

.btn-incident-all span {
    font-size: 0.9rem;
    font-weight: bold;
}

.btn-incident-all i {
    color: #000000;
    font-size: 1.5rem;
    margin-right: 5px;
}

.btn-incidents-all {
    color: #ffffff;
    background: linear-gradient(90deg, #0b76a0 0%, #0f9ed5 100%);
    display: flex;
    justify-content: center;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    margin: 10px 0px 0px 0px;
    padding: 5px 10px;
}

.btn-incidents-width {
    max-width: 350px;
}

.btn-incidents-all:hover {
    color: #ffffff;
}

.btn-incidents-all span {
    font-size: 0.9rem;
    font-weight: bold;
}

.btn-incidents-all i {
    color: #000000;
    font-size: 1.5rem;
    margin-right: 5px;
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.has-search .input-group-append {
    position: absolute;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    color: #aaa;
    float: right;
    margin-top: -38px;
    z-index: 200000;
    right: 28px;
}

.has-search .input-group-append .btn {
    outline: none;
}

.catalogue-search input {
    background-color: #e1f7ff !important;
}

.catalogue-search .input-group-append {
    right: 15px !important;
}

.form-group {
    margin-bottom: 5px !important;
}

.dot {
    height: 15px;
    width: 15px;
    background-color: #ffffff;
    border: 1px solid #0f9ed5;
    border-radius: 50%;
    float: right;
}

.dot.in-progress {
    background: linear-gradient(90deg, #0b76a0 0%, #0f9ed5 100%);
}

.dot.completed {
    background-color: #0b76a0;
}

.completed i {
    color: #215f9a;
}

.incomplete i {
    color: #a6a6a6;
}

.loading {
    clip-path: inset(0 3ch 0 0);
    animation: l 1s steps(4) infinite;
}

.instructions {
    line-height: 2;
}

.story-info {
    overflow: visible;
}

.correct-answer {
    background-color: #9ed59c !important;
    color: #ffffff;
    border-radius: 5px;
}

.list-correct-answer i {
    color: green !important;
}

.right-answer {
    background-color: #7c93dc !important;
    color: #ffffff;
    border-radius: 5px;
}

.list-right-answer i {
    color: green !important;
}

.wrong-answer {
    background-color: #ff796e !important;
    color: #ffffff;
    border-radius: 5px;
}

.list-wrong-answer i {
    color: red !important;
}

.has-grace-mark:after {
    content: "Grace Mark Added";
    background-color: #f7f7f7 !important;
}

.right-answer:after {
    content: "Correct Answer";
    background-color: #7c93dc !important;
}

.wrong-answer:after {
    content: "Your Answer";
    background-color: #ff796e !important;
}

.has-grace-mark:after {
    position: absolute;
    right: 25px;
    padding: 10px 10px 0px 10px;
    font-size: 13px;
    font-weight: bold;
    margin-top: 30px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #ced4da;
    border-top: none;
}

.right-answer:after,
.wrong-answer:after {
    position: absolute;
    right: 25px;
    padding: 10px 10px 0px 10px;
    font-size: 13px;
    font-weight: bold;
    margin-top: 35px;
    border-radius: 5px;
}

.correct-answer .p-checkbox.p-highlight .p-checkbox-box,
.wrong-answer .p-checkbox.p-highlight .p-checkbox-box {
    border-color: #ffffff;
    background: #ffffff;
}

.correct-answer .p-checkbox.p-highlight .p-checkbox-box .p-checkbox-icon {
    color: #9ed59c !important;
}

.wrong-answer .p-checkbox.p-highlight .p-checkbox-box .p-checkbox-icon {
    color: #ff796e !important;
}

.cancel-reason {
    min-height: 150px;
    resize: none;
}

.btn-processing {
    height: 36px;
    /* border-radius       : 20px !important; */
    pointer-events: none;
    opacity: 0.7;
    cursor: not-allowed;
    background-image: -webkit-linear-gradient(45deg,
            rgba(255, 255, 255, 0.1) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.1) 50%,
            rgba(255, 255, 255, 0.1) 75%,
            transparent 75%,
            transparent);
    background-image: -o-linear-gradient(45deg,
            rgba(255, 255, 255, 0.1) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.1) 50%,
            rgba(255, 255, 255, 0.1) 75%,
            transparent 75%,
            transparent);
    background-image: linear-gradient(45deg,
            rgba(255, 255, 255, 0.1) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.1) 50%,
            rgba(255, 255, 255, 0.1) 75%,
            transparent 75%,
            transparent);
    -webkit-background-size: 40px 40px;
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite;
    background-size: 36px 36px;
}

.btn-processing:hover {
    height: 36px;
    /* border-radius       : 20px !important; */
    pointer-events: none;
    opacity: 0.7;
    cursor: not-allowed;
    background-image: -webkit-linear-gradient(45deg,
            rgba(255, 255, 255, 0.1) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.1) 50%,
            rgba(255, 255, 255, 0.1) 75%,
            transparent 75%,
            transparent);
    background-image: -o-linear-gradient(45deg,
            rgba(255, 255, 255, 0.1) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.1) 50%,
            rgba(255, 255, 255, 0.1) 75%,
            transparent 75%,
            transparent);
    background-image: linear-gradient(45deg,
            rgba(255, 255, 255, 0.1) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.1) 50%,
            rgba(255, 255, 255, 0.1) 75%,
            transparent 75%,
            transparent);
    -webkit-background-size: 40px 40px;
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite;
    background-size: 36px 36px;
}

.mcq-confident-green {
    background-color: rgb(144, 238, 144);
    /* Light green for full-green */
    color: white;
}

.mcq-half-green {
    background-color: rgb(198, 255, 199);
    /* Lighter shade for half-green */
    color: black;
}

@keyframes l {
    to {
        clip-path: inset(0 -1ch 0 0);
    }
}

@media (max-width: 768px) {

    .incident-view-container,
    .incident-view-container>div {
        height: 100%;
        overflow-x: visible;
        overflow-y: visible;
        overflow: visible;
    }
}

@media print {
    .pdf-container {
        display: none;
    }
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 60px;
    height: 25px;
    border: 2px solid;
    box-sizing: border-box;
    border-radius: 50%;
    display: grid;
    animation: l2 2s infinite linear;
    margin: auto;
    margin-top: 20%;
}

.loader:before,
.loader:after {
    content: "";
    grid-area: 1/1;
    border: inherit;
    border-radius: 50%;
    animation: inherit;
    animation-duration: 3s;
}

.loader:after {
    --s: -1;
}

@keyframes l2 {
    100% {
        transform: rotate(calc(var(--s, 1)*1turn))
    }
}