:focus-visible {
    outline: none;
}

a {
    color: #160f0f;
}

a:hover {
    color: #160f0f;
}

.identity-container {
    /* height: calc(100vh); */
    background-image: url(/public/assets/img/background.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}

.identity-container {
    background: none;
}

.identity-container .identity-image {
    display: none;
}

.identity-container .row {
    margin-left: 0px;
    margin-right: 0px;
}

.identity-container .identity-image {
    height: calc(100vh - 90px);
}

.identity-container .identity-image img {
    margin-top: 5rem;
    height: 25rem;
    top: 7rem;
    right: 5rem;
    position: absolute;
}

/* .identity-container .identity-form {
  padding: 15rem 15rem 0rem 15rem;
  margin: auto;
} */

.identity-container .identity-form h1 {
    font-size: 24px;
    /* font-family: Arial Black, Arial Bold, Gadget, sans-serif; */
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    font-weight: bold;
    color: #0b76a0;
    text-align: center;
}

/* .identity-container .identity-form form {
  margin: auto;
  padding: 1rem 2rem;
} */

.identity-container .identity-form form .captcha-holder > div > div > div {
    margin: auto;
}

.identity-container .identity-form form .input-icons {
    margin: auto;
    position: relative;
}

.identity-container .identity-form form .input-icons .input-container-username:before {
    content: "";
    position: absolute;
    background-color: #fff;
    top: 3.5px;
    left: 19px;
    width: 37px;
    height: 37px;
    border-radius: 100%;
    /* rounded corners */
    box-shadow: 0 0 10px rgb(37, 36, 36);
    /* shadow effect */
    background-image: url(/public/assets/img/user.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    z-index: 1;
    /* place behind the input field */
}

.identity-container .identity-form form .input-icons .input-container-password:before {
    content: "";
    position: absolute;
    background-color: #fff;
    top: 3.5px;
    left: 19px;
    width: 37px;
    height: 37px;
    border-radius: 100%;
    /* rounded corners */
    box-shadow: 0 0 10px rgb(37, 36, 36);
    /* shadow effect */
    background-image: url(/public/assets/img/lock.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    z-index: 1;
    /* place behind the input field */
}

.identity-container .identity-form form .input-icons input {
    background-color: #f5f5f5;
    border: none;
    padding: 10px 10px 10px 60px;
    display: block;
    border-radius: 2rem 0.5rem 0.5rem 2rem;
    width: 100%;
}

.identity-container .identity-form form .input-icons .otp-textbox {
    text-align: center;
    padding: 10px 10px 10px 10px;
}

.identity-container .identity-form form .identity-button {
    display: inline-block;
    background-color: #00b0f0;
    border: none;
    padding: 0.35rem 1.5rem;
    border-radius: 10px;
    color: #fff;
    margin: auto;
    align-items: center;
}

.identity-container .identity-form form .identity-cancel-button {
    display: inline-block;
    background-color: grey;
    border: none;
    padding: 0.35rem 1.5rem;
    border-radius: 10px;
    color: #fff;
    margin: auto;
    align-items: center;
}

.identity-container .identity-form form .signup-button {
    display: block;
    background-color: #0070c0;
    border: none;
    padding: 0.35rem 1.5rem;
    border-radius: 10px;
    color: #fff;
    margin: auto;
    align-items: center;
}

input:focus-visible {
    outline-offset: none;
}

/* The container */
.identity-container .identity-form form .checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: bold;
}

/* Hide the browser's default checkbox */
.identity-container .identity-form form .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.identity-container .identity-form form .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #f5f5f5;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.identity-container .identity-form form .checkbox-container:hover input ~ .checkmark {
    background-color: #f5f5f5;
}

/* When the checkbox is checked, add a blue background color */
.identity-container .identity-form form .checkbox-container input:checked ~ .checkmark {
    background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.identity-container .identity-form form .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.identity-container .identity-form form .checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.identity-container .identity-form form .checkbox-container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.identity-container .questions-form {
    height: calc(100vh);
    padding: 12rem 0rem 0rem 0rem;
    margin: auto;
}

.identity-container .questions-form form table {
    width: 100%;
    max-width: 900px;
}

.identity-container .questions-form form table th,
.identity-container .questions-form form table td {
    width: 45%;
    max-width: 45%;
    min-width: 45%;
}

.identity-container .questions-form form table th:first-child,
.identity-container .questions-form form table td:first-child {
    width: 10% !important;
    max-width: 10% !important;
    min-width: 10% !important;
}

.no-background {
    background-image: none !important;
}

.identity-container .p-inputotp {
    margin: auto;
    max-width: 275px;
}

.identity-container .otp-holder .p-inputotp {
    margin: auto;
    max-width: 375px;
}

.answers {
    padding-top: 10rem;
    font-size: 18px;
}

.v-scroll,
.p-tree {
    overflow-y: auto;
    height: calc(100vh - 150px);
}

.p-button {
    padding: 0.35rem 1.5rem;
    border-radius: 10px;
}

@media (min-width: 200px) {
    .identity-container .identity-form {
        padding: 10rem 1rem;
    }
}

@media (min-width: 576px) {
    .identity-container .identity-form {
        padding: 15rem 5rem;
    }
}

@media (min-width: 768px) {
    .identity-container .identity-form {
        padding: 10rem 10rem;
    }
}

@media (min-width: 992px) {
    .identity-container .identity-form {
        padding: 15rem 20rem;
    }
}

@media (min-width: 1200px) {
    .identity-container {
        background-image: url(/public/assets/img/background.png);
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
    }

    .identity-container .identity-form {
        padding: 7rem 1rem;
    }

    .identity-container .identity-form form {
        padding: 0px;
    }

    .identity-container .identity-form .input-icons input {
        display: block;
        margin: 0 auto;
    }

    .identity-container .identity-form .checkbox-container {
        padding: auto;
    }

    .change-password {
        max-width: 800px;
    }
}
