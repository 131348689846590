.users .row {
    border-top: 1px solid #eee;
}

.users .row:last-child {
    border-bottom: 1px solid #eee;
}

.users .row .header-cell {
    border-left: 1px solid #eee;
    font-weight: bold;
}

.users .row .header-cell:last-child {
    border-right: 1px solid #eee;
}

.users .row .value-cell {
    border-left: 1px solid #eee;
}

.users .row .value-cell:first-child {
    border-left: none;
}

.users .scroller {
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
}

.users .scroller,
.users .no-records {
    width: 100%;
    overflow-x: none;
    overflow-y: auto;
    height: calc(100vh - 265px);
}

.users .no-records {
    border-bottom: none !important;
}

.users .pager {
    float: right;
    display: table-row;
}

.users .pager button {
    display: table-cell;
}

.users .pager .select-pagesize {
    width: 100px;
    display: table-cell;
}

.users .scroller .row {
    cursor: pointer;
}

.users .scroller .row.selected {
    background-color: #d9d9d9;
}

.view .ticket-container {
    overflow-y: auto;
}

.view .ticket-container .border-bottom {
    border-bottom: 1px solid #ddd;
}

.view .ticket-container .comment {
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 100%;
}

.view .ticket-container .comment .file-selector {
    cursor: pointer;
}

.view .ticket-container .comment textarea {
    min-height: 150px;
}

.view .ticket-container .comment .custom-file-label {
    border: 1px solid #ddd;
}
